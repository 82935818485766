import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Card } from 'src/assets/models/Card.model';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent  {

  @Input({ required: true }) cardObj: Card;
  @Output() link=new EventEmitter<Card>(); 

  constructor() {

  }
  ngOnInit(): void {   

  }
  protected emitLink() : void{
    if (!!this.cardObj.file) {
      this.link.emit(this.cardObj);
    }
  }
}