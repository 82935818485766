import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sl06',
  templateUrl: './sl06.component.html',
  styleUrls: ['./sl06.component.scss']
})
export class SL06Component {
  
  protected company: string = environment.company;
  @Input() package: any;
  @Output() link = new EventEmitter<string>();

  emitLink(link: string): void {
    this.link.emit(link);
  }
}
