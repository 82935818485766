import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  protected translationLoaded: boolean = false;

  constructor(
    private translate: TranslateService,
    private title: Title,
    private router:Router, 
    private googleAnalyticsService: GoogleAnalyticsService,
    private cookieBotService: NgxCookiebotService
  ) {
  }

  ngOnInit(): void {   
    this.loadLanguages();
    this.loadGoogleAnalytics();
    this.loadCookieBotService();
  }

  private loadLanguages() : void {
    this.translate.addLangs(['en', 'it']);
    this.translate.setDefaultLang('en');
    if(!localStorage.getItem('language')){
      const browserLang = this.translate.getBrowserLang();
      this.translate.use(browserLang.match(/en|it/) ? browserLang : 'en').subscribe(() => {
        this.translationLoaded = true;
        this.title.setTitle(this.translate.instant("title"));
        localStorage.setItem('language', this.translate.currentLang);
      });
    } else{
      this.translate.use(localStorage.getItem('language')).subscribe(() => {
        this.translationLoaded = true;
        this.title.setTitle(this.translate.instant("title"));
      });
      
    }

  }

  private loadGoogleAnalytics(): void {
    this.googleAnalyticsService.loadConfigurations();
     this.router.events.subscribe(event=> {
       if (event instanceof NavigationEnd){
           this.googleAnalyticsService.sendPageView(this.title.getTitle(), event.urlAfterRedirects, document.location.href);
       }
     });
  }

  private loadCookieBotService(): void {
    this.cookieBotService.onServiceReady$.pipe(
      filter((ready: boolean) => {
        return ready;
      })
    ).subscribe(() => {
      // this._cookieBotService.cookiebot is available
    });
  }

}
