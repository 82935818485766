<div id="SL04" class="block black block-bg" [style.background-image]="'url(' + package.background + ')'">
    <div class="left-half cut-bg"></div>
    <div class="right-half flex-center-v  ">
        <div class="cage responsive-only">
            <div data-aos="fade-up" class="ctr-start-1-6 headline_01_xl_light flex-col" style="margin-bottom: 24px;">
                {{ package.title001 | uppercase }}<br />
                <span class="headline_01_xl_semibold">{{ package.title002 | uppercase }}</span>
            </div>
            <div class="box ctr-start-1-6 cage-5" style="grid-gap: 24px;">
                <ng-container *ngIf="slide002Items?.length > 0">
                    <ng-container *ngFor="let item of slide002Items, let i = index">
                        <div data-aos="fade-up" class="ctr-start-1-6 flex container cursor">
                            <img class="ctr-start-1-1 cursor" [src]="'./assets/images/Icons/'+package.openicon"
                                (click)="toggleSl04Element(item, i)"
                                [@expandCollapse]="item.active ? 'inactive' : 'active'" style="margin-right: 24px;">
                            <div data-aos="fade-left" *ngIf="item.active == true" class="magic-bg"></div>
                            <div data-aos="fade-left" *ngIf="item.active">
                                <img class="ctr-start-1-1 cursor" [src]="'./assets/images/Icons/'+package.closeicon"
                                    [@expandCollapse]="item.active ? 'active' : 'inactive'" style="margin-right: 24px;">
                            </div>
                            <div class=" flex-col">
                                <div class="headline_03 ctr-start-2-4 " (click)="toggleSl04Element(item, i)">
                                    {{item.title | uppercase}}
                                </div>
                                <div class="text-cont ctr-start-2-4 "
                                    [@expandCollapse]="item.active ? 'active' : 'inactive'">
                                    <div class="body_03_xl_regular">
                                        {{item.text}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="grid-overlay desktop-only">
        <div class="cage">
            <div data-aos="fade-up" class="ct-start-8-5 ctr-start-1-6 headline_01_xl_light flex-col"
                style="margin-bottom: 24px;">
                {{ package.title001 | uppercase }}<br />
                <span class="headline_01_xl_semibold">{{ package.title002 | uppercase }}</span>
            </div>
            <div class="box ct-start-8-5 cage-6">
                <ng-container *ngIf="slide002Items?.length > 0">
                    <ng-container *ngFor="let item of slide002Items, let i = index">
                        <div data-aos="fade-up" class="ct-start-1-6 flex container cursor">
                            <img class="ct-start-1-1 cursor" [src]="'./assets/images/Icons/'+package.openicon"
                                (click)="toggleSl04Element(item, i)"
                                [@expandCollapse]="item.active ? 'inactive' : 'active'" style="margin-right: 24px;">
                            <div data-aos="fade-left" *ngIf="item.active == true" class="magic-bg"></div>
                            <div data-aos="fade-left" *ngIf="item.active">
                                <img class="ct-start-1-1" [src]="'./assets/images/Icons/'+package.closeicon"
                                    [@expandCollapse]="item.active ? 'active' : 'inactive'" style="margin-right: 24px;">
                            </div>
                            <div class="flex-col">
                                <div class="headline_03 ct-start-2-4" (click)="toggleSl04Element(item, i)">
                                    {{item.title | uppercase}}
                                </div>
                                <div class="text-cont ct-start-2-4"
                                    [@expandCollapse]="item.active ? 'active' : 'inactive'">
                                    <div class="body_03_xl_regular">
                                        {{item.text}}
                                    </div>
                                    <div class="pad"></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>