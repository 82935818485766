import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DiagnosticRestResultContainerMailResponseRestObject, MailRequestRestObject, MailsControllerService } from 'src/services/api-docs-services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sl08',
  templateUrl: './sl08.component.html',
  styleUrls: ['./sl08.component.scss']
})
export class SL08Component implements OnInit {


  @Output() link = new EventEmitter<string>();
  
  protected form: FormGroup;
  protected translationForm : any;
  protected showPopup: boolean = false;
  protected errorManager: any = {
    firstName: false,
    lastName: false,
    phone: false,
    company: false,
    email: false,
    message: false,
    policy: false
  }

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private fb: FormBuilder,
    private mailsControllerService: MailsControllerService,
  ) {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
      policy: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    this.getElements();
  } 

  private getElements(): void {
    this.http.get('assets/'+environment.company+'/content/' + this.translate.currentLang + '/misc.json', { responseType: 'text' }).subscribe((data) => {
      this.translationForm = JSON.parse(data).form;
    });
  }

 

  protected onSubmit(): void {
    if (this.form.valid) {
      const mailObject: MailRequestRestObject = {
        recipients : {
          TOS : ["info@dodifferent.it"]
        },
        mailCode : environment.contactTemplate,
        data: {
          name : this.form.value["firstName"],
          surname : this.form.value["lastName"],
          phone : this.form.value["phone"],
          company : this.form.value["company"],
          email : this.form.value["email"],
          message : this.form.value["message"]
        }
      }
      this.mailsControllerService.sendMail(mailObject).subscribe(
        (response : DiagnosticRestResultContainerMailResponseRestObject)=>{
          if(response.diagnostic.esit=='OK'){
            this.togglePopup();   
          }else
          {
            return
          }
        }
      )
      
    } else {
      this.analyzeForm();
    }
  }

  protected togglePopup(): void {
    this.showPopup = !this.showPopup;
  }
  
  closePopupAndReset() {
    this.togglePopup();
    //this.form.reset();}
      this.form = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phone: ['', Validators.required],
        company: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        message: ['', Validators.required],
        policy: [false, Validators.requiredTrue]
      });
    }
 


    private analyzeForm(): void {
      this.errorManager.firstName = this.form.controls['firstName'].invalid;
      this.errorManager.lastName = this.form.controls['lastName'].invalid;
      this.errorManager.phone = this.form.controls['phone'].invalid;
      this.errorManager.company = this.form.controls['company'].invalid;
      this.errorManager.email = this.form.controls['email'].invalid;
      this.errorManager.message = this.form.controls['message'].invalid;
      this.errorManager.policy = this.form.controls['policy'].invalid;
  }

  emitLink(link: string): void {
    this.link.emit(link);
  }
    
  }





