import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sl03',
  templateUrl: './sl03.component.html',
  styleUrls: ['./sl03.component.scss']
})
export class SL03Component {
  @Input() dark: boolean=false;
  @Input({required: true}) package: any;

  constructor(
    private router: Router
  ) {

  }

  protected buttonClick(link: string): void {
    this.router.navigate([link]);
  }
}
