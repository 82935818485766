import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sl00',
  templateUrl: './sl00.component.html',
  styleUrls: ['./sl00.component.scss']
})
export class SL00Component implements OnInit {

  protected company: string = environment.company;
  @Input() package: any;
  @Output() link = new EventEmitter<string>();

  ngOnInit(): void {
    this.playVideoWorkaround();
  }

  protected scrollTo(target: string): void {
    document.getElementById(target).scrollIntoView()
  }

  private playVideoWorkaround(): void {
    const videoElement = document.querySelector('video');

    if (!!videoElement && videoElement instanceof HTMLVideoElement) {
      videoElement.muted = true;
      videoElement.play();
    }
  }

  emitLink(link: string): void {
    this.link.emit(link);
  }
}
