<img [src]="cardObj.img">
<div class="holder" [ngClass]="{'cursor' : !!cardObj.file}" (click)="emitLink()">
    <div class="text-container" data-aos="fade-up">
        <div class="banner"></div>
        <div class="headline caption_title_xl">
            <b> {{cardObj.headline}}</b>
        </div>
        <div *ngIf="!!cardObj.caption" class="overlay-text caption_detail_xl">
            <span>{{cardObj.caption}}</span>
        </div>
    </div>
</div>
<div class="black-veil">