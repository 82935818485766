import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'slide-gen',
  templateUrl: './slide-gen.component.html',
  styleUrls: ['./slide-gen.component.scss']
})
export class SlideGenComponent implements OnInit {

  @Input({required: true}) package: any;
  
  @Output() SL00Data = new EventEmitter<string>();
  @Output() SL01Data = new EventEmitter<string>();
  @Output() SL06Data = new EventEmitter<string>();
  @Output() SL08Data = new EventEmitter<string>();
  @Output() SL09Data = new EventEmitter<string>();
  @Output() SL18Data = new EventEmitter<string>();
  @Output() SL19Data = new EventEmitter<string>();

  ngOnInit(): void {
    
  }

  protected emitSL00(data: string): void {''
    this.SL00Data.emit(data);
  }
  
  protected emitSL01(data: string): void {
    this.SL01Data.emit(data);
  }
  
  protected emitSL06(data: string): void {
    this.SL06Data.emit(data);
  }

  protected emitSL08(data: string): void {
    this.SL08Data.emit(data);
  }
  
  protected emitSL09(data: string): void {
    this.SL09Data.emit(data);
  }  
  
  protected emitSL18(data: string): void {
    this.SL18Data.emit(data);
  }

  protected emitSL19(data: string): void {
    this.SL19Data.emit(data);
  }
}
