<ng-container *ngIf="isScrollOnTop()">
    <div class="navbar" [ngClass]="{'nullify': sidePage}" data-aos="fade-down">
        <div class="cage">
            <div class="ct-start-1-2 ctr-start-1-2 flex-center cursor">
                <img [src]="'./assets/'+company+'/images/'+menu.logo" (click)="theGreatReturn()">
            </div>
            <div *ngIf="!sidePage" class="ct-start-4-6 flex-center-v tabs-container">
                <div class="cursor menu_label_xl" *ngFor="let tab of menu.menu"
                  (click)="emitScrollingTarget(tab)">
                    <span data-aos="fade-in">{{ tab.title }}</span>
                </div>
            </div>
            <div class="ct-start-12-1 ctr-start-4-3 flex-center">
                <ng-container *ngIf="!sidePage">
                    <div class="cursor primary_button" *ngFor="let obj of sideLangs" (click)="emitLanguageTarget(obj.lang)">
                        <span *ngIf="obj.visible" data-aos="fade-in">{{ 'languages.' + obj.lang | translate | uppercase}}</span>
                    </div>
                </ng-container>
                <button *ngIf="sidePage" (click)="theGreatReturn()">{{ menu.back | uppercase}}</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isScrollOnTop()">
    <div class="sticky" [ngClass]="{'nullify': sidePage}" data-aos="fade-down">
        <div class="cage">
            <div class="ct-start-1-1 flex-center cursor" (click)="resetScroll()">
                <img [src]="'./assets/'+company+'/images/'+menu.logominimal">
            </div>
            <div *ngIf="!sidePage" class="ct-start-4-6 flex-center-v tabs-container">
                <div class="cursor menu_label_xl" *ngFor="let tab of menu.menu"
                    (click)="emitScrollingTarget(tab)">
                    <span data-aos="fade-in">{{ tab.title }}</span>
                </div>
            </div>
            <div class="ct-start-12-1 ctr-start-4-3 flex-center">
                <ng-container *ngIf="!sidePage">
                    <div class="cursor primary_button" *ngFor="let obj of sideLangs" (click)="emitLanguageTarget(obj.lang)">
                        <span *ngIf="obj.visible" data-aos="fade-in">{{ 'languages.' + obj.lang | translate | uppercase}}</span>
                    </div>
                </ng-container>
                <button *ngIf="sidePage" (click)="theGreatReturn()">{{ menu.back | uppercase }}</button>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="!sidePage" class="holder" data-aos="fade-down">
    <div class="resp-container off flex-col" [ngClass]="{'on' : isResponsiveOpen}">
        <div class="cage">
            <div class="ctr-start-1-1 flex-center" *ngFor="let obj of sideLangs" (click)="emitLanguageTarget(obj.lang)">
                <button *ngIf="obj.visible" data-aos="fade-in">{{ 'languages.' + obj.lang | translate | uppercase}}</button>
            </div>
    
            <div class="ctr-start-6-1 " (click)="toggleRespContainer()" >
                <img [src]="'./assets/images/Icons/'+menu.closeicon">
            </div>
        </div>
        <div class="body">
            <div class="cage menu-cont">
                <div class="ctr-start-1-6" style="flex:1;" >
                    <responsivemenu *ngIf="!!menu.menu" [menu]="menu.menu" (link)="emitScrollingTarget($event)"></responsivemenu> 
                </div>
            </div>
            <img class="logo" [src]="'./assets/'+company+'/images/'+menu.logominimal">
        </div>
    </div>
    <div class="cage header ">
        <div class="ctr-start-1-2 flex-center" data-aos="fade-down" *ngIf="isScrollOnTop()">
            <img [src]="'./assets/'+company+'/images/'+menu.logo">
        </div>  
        <div class="ctr-start-6-1 menu-burger flex-center" (click)="toggleRespContainer()" >
            <img [src]="'./assets/images/Icons/'+menu.burgericon">
        </div>
    </div>
</div>
