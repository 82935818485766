<div id="SL16" class="block white">
    <div class="cage">
        <div data-aos="fade-up" class="ct-start-2-10 ctr-start-1-6 headline_01_xl_light">
            {{package.title000|uppercase}} <span class="headline_01_xl_semibold"> {{package.title001|uppercase}}</span>
        </div>

        <div data-aos="fade-up" class="ct-start-2-10 ctr-start-1-6 body_01_xl_light box-text">
            {{ package.text000 }}
        </div>
        <div *ngIf="!!package.elements" data-aos="fade-up" class="ct-start-2-10 ctr-start-1-6">
            <ng-container *ngFor="let assetObj of package.elements, let i = index">
                <div class="holder cage" data-aos="fade-up">
                    <div class="ct-start-1-4 ctr-start-1-6 left">
                        <img [src]="assetObj.img" />
                    </div>
                    <div class="ct-start-5-8 ctr-start-1-6 flex-col services right">
                        <div class="headline_02_xl"> {{assetObj.title | uppercase}} </div>
                        <div class="body_01_xl_light"> {{assetObj.subtitle}} </div>
                        <div class="body_02_xl_regular"> {{assetObj.text000}} </div>
                        <div class="body_02_xl_regular title-services" > {{assetObj.text001}} </div>
                        <div *ngIf="!!assetObj.services" class="cage-2">
                            <ng-container *ngFor="let item of assetObj.services">
                                <div class="flex main-slot">
                                    <div class="img-slot flex-center-v">
                                        <img [src]="item.img" />
                                    </div>
                                    <div class="caption_title_s textbox flex-center-v">
                                        <span>
                                            {{item.title}}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>                
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>