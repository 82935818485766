<div id="SL12" class="block  black">
    <video class="video-box" autoplay muted loop playsinline [src]="package.background"
        data-object-fit="cover"></video>
    <div class="black-veil"></div>
    <div class="cage">
        <div data-aos="fade-up" class="ct-start-2-10 ctr-start-1-6 headline_01_xl_semibold text-container title">
            {{ package.title001 | uppercase }}
        </div>
        <div class="cage-10 ct-start-2-10 ctr-start-1-6">
            <div class="flex ct-start-1-10 text-center text-container">
                <div class=" flex-col flex-center slot">
                    <span data-aos="fade-up" class="display_xl"> {{ package.head001 }}</span>
                    <span data-aos="fade-up" class="body_01_xl_light flex-center"> {{ package.line001 }}</span>
                </div>
                <div *ngIf="package.head002==0 && !!package.line002" class="flex-center-h slot display_xl " data-aos="fade-up right-half">
                    <span data-aos="fade-up" class="flex-center">{{ package.line002 | uppercase }}</span>
                </div>
                <div  *ngIf="!!package.head002" class="flex-col flex-center slot center-half">
                    <span data-aos="fade-up" class="display_xl">{{ package.head002 }}</span>
                    <span data-aos="fade-up" class="body_01_xl_light flex-center">{{ package.line002 }} </span>
                </div>
                <div *ngIf="package.head003==0 && !!package.line003" class="flex-center-h slot display_xl " data-aos="fade-up right-half">
                    <span data-aos="fade-up" class="flex-center">{{ package.line003 | uppercase }}</span>
                </div>
                <div *ngIf="!!package.head003" class="flex-col flex-center-h slot" data-aos="fade-up right-half">
                    <span data-aos="fade-up" class="display_xl">{{ package.head003 }}</span>
                    <span data-aos="fade-up" class="body_01_xl_light flex-center">{{ package.line003 }}</span>
                </div>


            </div>
        </div>

    </div>
</div>
