import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  
  private trackingID: string;

  constructor() {
    this.trackingID = environment.googleAnalyticsTrackingID;
  }

  public loadConfigurations() {
    let gaScript = document.createElement("script");
    gaScript.setAttribute("async", "true");
    gaScript.setAttribute(
      "src",
      `https://www.googletagmanager.com/gtag/js?id=${this.trackingID}`
    );
    document.documentElement.firstChild.appendChild(gaScript);

    let gaScript2 = document.createElement("script");
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${this.trackingID}\');`;
    document.documentElement.firstChild.appendChild(gaScript2);
  }

  public sendPageView(title: string, location: string, url: string) {
    gtag("event", "page_view", {
      page_title: title,
      page_location: location,
      page_path: url,
    });
  }

  public sendEvent(action: string, category: string, label: string) {
    gtag("event", action, {
      event_category: category,
      event_label: label,
    });
  }
}
