<div class="cursor block flex-center-v">
    <div class="cage-6">
        <div class="label-container ctr-start-1-6 cage-6 main-label" *ngFor="let tab of menu" (click)="emitLink(tab)">
            <div class="ctr-start-1-6 cage-6" (click)="toggleTab(tab)">
                <div class="cursor headline_03 gap ctr-start-1-5">
                    {{ tab.title | uppercase }}                    
                </div>
                <div *ngIf="tab.children && !!tab.isOpen" class="ctr-start-6-1 expand" [ngClass]="{'minus' : tab.isOpen, 'plus' : !!tab.children}"></div>
            </div>

            <div *ngIf="!!tab.children && !!tab.isOpen && tab.isOpen" class="ctr-start-1-6 cage-6 sub-label">
                <div class="cursor body_03_xl_regular ctr-start-1-5" *ngFor="let subItem of tab.children" (click)="emitLink(subItem)">
                    {{ subItem.title }}
                </div>
            </div>
        </div>
    </div>
</div>





