import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Card } from 'src/assets/models/Card.model';
import { ContentSelector } from 'src/assets/models/ContentSelector.enum';
import { Language } from 'src/assets/models/Language.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'page-owner',
  templateUrl: './page-owner.component.html',
  styleUrls: ['./page-owner.component.scss']
})
export class PageOwnerComponent {

  @Input() contentHook: ContentSelector = ContentSelector.Page10;
  protected translation: any;
  protected translationForm: any;
  protected translationTFooter: any;
  protected menu: any;
  protected sideLangs: any[];
  private clientFile: string;
  protected isHome: boolean = false;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.determineHook();
    this.getSideLanguage();
    this.getTranslation();
  }

  private determineHook(): void {
    switch ((this.route?.url as any)?.value[0]?.path) {
      case 'details':
        this.contentHook = ContentSelector.Page11;
        break;
    
      case 'solutions':
        this.contentHook = ContentSelector.Page12;
        break;

      case 'privacy':
        this.contentHook = ContentSelector.Page13;
        break;

      case 'cookies':
        this.contentHook = ContentSelector.Page14;
        break;
      
      case 'news':
        this.contentHook = ContentSelector.Page15;
        break;

      case 'article':
        this.contentHook = ContentSelector.Page16;
        break;

      case 'test':
        this.contentHook = ContentSelector.Page17;
       break;

      default:
        break;
    }
  }

  private getTranslation(): void {
    //menu
    this.http.get('assets/'+environment.company+'/content/'+this.translate.currentLang+'/navigation.json', { responseType: 'text' }).subscribe((data) => {
      this.menu = JSON.parse(data);
    });

    //content
    switch (this.contentHook) {
      case ContentSelector.Page10:
        this.http.get('assets/'+environment.company+'/content/'+this.translate.currentLang+'/home.json', { responseType: 'text' }).subscribe((data) => {
          this.translation = JSON.parse(data).slides;
          this.isHome = true;
        });
        break;
      case ContentSelector.Page11:
        this.route.paramMap.subscribe(params => {
          let fileName: string = params.get('client');
          if (fileName) {
            this.clientFile = fileName+'.json';
            this.http.get('assets/'+environment.company+'/content/'+this.translate.currentLang+'/bestcases/'+this.clientFile, { responseType: 'text' }).subscribe(
              (data) => {
                this.translation = JSON.parse(data).slides;
              },
              (error) => {
                if (error.status === 404) {
                  this.router.navigate([""]);
                }
              }
            );
            window.scrollTo(0, 0);
          } else {
            this.router.navigate([""]);
          }
          window.scrollTo(0, 0);
        });
        break;
        
      case ContentSelector.Page12:
        this.http.get('assets/'+environment.company+'/content/' + this.translate.currentLang + '/solutions.json', { responseType: 'text' }).subscribe((data) => {
          this.translation = JSON.parse(data).slides;
        });
        break;

      case ContentSelector.Page13:
        this.http.get('assets/'+environment.company+'/content/' + this.translate.currentLang + '/privacy.json', { responseType: 'text' }).subscribe((data) => {
          this.translation = JSON.parse(data).privacy;
        });
        break;

      case ContentSelector.Page14:
        this.http.get('assets/'+environment.company+'/content/' + this.translate.currentLang + '/cookies-policy.json', { responseType: 'text' }).subscribe((data) => {
          this.translation = JSON.parse(data).cookies;
        });
        break;
      
      case ContentSelector.Page15:
        this.http.get('assets/'+environment.company+'/content/' + this.translate.currentLang + '/newArticle.json', { responseType: 'text' }).subscribe((data) => {
          this.translation = JSON.parse(data).slides;
        });
        break;
      
      case ContentSelector.Page16:
        this.route.paramMap.subscribe(params => {
          let fileName: string = params.get('client');
          if (fileName) {
            this.clientFile = fileName+'.json';
            this.http.get('assets/'+environment.company+'/content/'+this.translate.currentLang+'/news/'+this.clientFile, { responseType: 'text' }).subscribe(
              (data) => {
                this.translation = JSON.parse(data).slides;
              },
              (error) => {
                if (error.status === 404) {
                  this.router.navigate([""]);
                }
              }
            );
            window.scrollTo(0, 0);
          } else {
            this.router.navigate([""]);
          }
          window.scrollTo(0, 0);
        });
        break;

        case ContentSelector.Page17:
        this.http.get('assets/'+environment.company+'/content/it/test.json', { responseType: 'text' }).subscribe((data) => {
          this.translation = JSON.parse(data).slides;
        });
        break;
    }

    //top-footer
    this.http.get('assets/'+environment.company+'/content/'+this.translate.currentLang+'/misc.json', { responseType: 'text' }).subscribe((data) => {
      this.translationTFooter = JSON.parse(data).topfooter;
    });
  }

  private getSideLanguage(): void {
    this.sideLangs = [];
    let langs: string[] = this.translate.getLangs();
    langs.forEach(element => {
      let obj: Language = {
        lang: element,
        visible: true
      }

      if (this.translate.currentLang != obj.lang) {
        this.sideLangs.push(obj);
      }

    });
  }

  protected scrollTo(destination: string): void {
    if (destination.startsWith('#')){
      document.getElementById(destination.replace('#','')).scrollIntoView();
    } else if (destination.startsWith('/')) {
      this.router.navigate([destination]);
    } else {
      window.open(destination, "_blank");
    }
  }

  protected updateLangs(selectedLang: string): void {
    this.translate.use(selectedLang).subscribe(
      () => {
        this.getTranslation();
        this.getSideLanguage();
        localStorage.setItem('language', selectedLang); 
      });
    }
    
  }

