
<div class="container flex">
    <div class="arrow-slot" (click)="scrollLeft()">
        <img class="hide-arrow" [ngClass]="{'show-arrow' : slideIndex > 0}" src="./assets/images/Icons/Arrow_Left.svg">
    </div>

    <div #showlist class="show-list flex-center-v">
        <div #backstage class="backstage flex-center-v">
            <div class="slot flex-center" *ngFor="let obj of list; let i = index">
                <img class="logo" [src]="obj.img">
            </div>
        </div>
    </div>

    <div class="arrow-slot" (click)="scrollRight()">
        <img class="hide-arrow" [ngClass]="{'show-arrow' : determineEndingPoint()}" src="./assets/images/Icons/Arrow_Right.svg">
    </div>
</div>


        
      


      
  