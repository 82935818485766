
<div id="SL03" class="block white" [ngClass]="{'black': dark}">
    <div class="cage">
        <div class="ct-start-2-10 ctr-start-1-6 flex-col titles">
            <div class="headline_01_xl_light" data-aos="fade-up">{{ package.title001 | uppercase }}<span class="headline_01_xl_semibold">{{ package.title002 | uppercase }}</span></div>
            <div class="body_01_xl_light subtitle" [innerHTML]="package.title003" data-aos="fade-up"> </div>
        </div>

        <ng-container *ngFor="let item of package.elements, let i = index">
            <div class="ct-start-3-4 ctr-start-1-6" *ngIf="!(i % 2) || i == 0">
                <package [element]="item"></package>
            </div>

            <div class="ct-start-8-4 ctr-start-1-6" *ngIf="!(!(i % 2) || i == 0)">
                <package [element]="item"></package>
            </div>
        </ng-container>
        <button *ngIf="!!package.button001 && !!package.buttonLink" class="ct-start-2-10" (click)="buttonClick('/'+package.buttonLink)"> {{ package.button001 | uppercase }} </button>
    </div>
</div>
