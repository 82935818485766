<ng-container *ngIf="!!packageCards">
    <div id="SL07" class="block black ">
        <div class="cage">
            <div class="title ct-start-2-11 ctr-start-1-6 headline_01_xl_light" data-aos="fade-up"> 
                {{ package.title000 | uppercase }}<span class="headline_01_xl_semibold">{{ package.title001 | uppercase }}</span>
            </div>
    
            <div *ngIf="!!packageCards.menu" class="title ct-start-2-10 ctr-start-1-6 menu-cont" data-aos="fade-up">               
                <slide-navbar [list]="packageCards.menu" (category)="assignCategory($event)"></slide-navbar>
            </div>
    
            <div class="ct-start-2-10 ctr-start-1-6 cage-2 card-container" data-aos="fade-up">
                <ng-container *ngFor="let item of packageCards.items, let i = index">
                    <div class="card-slot" *ngIf="item.category == this.slide004CategoryInView"  data-aos="fade-up">
                        <card [cardObj]="item" (link)="navigateTo($event)"></card>  
                    </div>
                </ng-container>
    
                <ng-container *ngFor="let item of packageCards.items, let i = index">
                    <div class="card-slot" *ngIf="!this.slide004CategoryInView && item.highlight"  data-aos="fade-up">
                        <card [cardObj]="item" (link)="navigateTo($event)"></card>  
                    </div>
                </ng-container>
            </div>  
        </div>
    </div>
</ng-container>