<div id="SL15" class="block black ">
    <div class="cage">

        <div data-aos="fade-up" class="ct-start-2-10 ctr-start-1-6 border"> </div>

        <div data-aos="fade-up" class="ct-start-2-5 ctr-start-1-6 body_02_xl_regular bodytext">
            {{package.text000}} 
        </div>

        <div data-aos="fade-up" class="ct-start-7-5 ctr-start-1-6 body_02_xl_regular bodytext">
            {{ package.text001 }}
        </div>
    </div>
</div>