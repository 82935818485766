import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sl16',
  templateUrl: './sl16.component.html',
  styleUrls: ['./sl16.component.scss']
})
export class SL16Component {
  @Input() package: any;

  constructor(
    private translate: TranslateService,
    private http: HttpClient
  ) {
    
  }
 
  ngOnInit(): void {
    
  }
}
