import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { MenuTab } from 'src/assets/models/MenuTab.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {

  protected company: string = environment.company;
  @Input({required: true}) menu: any;
  @Input({required: true}) sideLangs: any[];
  @Input() sidePage: boolean = true;
  @Output() scrollingTarget = new EventEmitter<string>();
  @Output() languageTarget = new EventEmitter<string>();
  @Output() link = new EventEmitter<string>();

  protected isResponsiveOpen : boolean = false;

  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {

  }

  ngOnInit(): void {
    
  }

  protected emitScrollingTarget(tab: MenuTab): void {
    this.toggleRespContainer();
    this.scrollingTarget.emit(tab.link);
    this.googleAnalyticsService.sendEvent('click', 'menu', tab.title);
  }

  protected emitLanguageTarget(lang: string): void {
    this.languageTarget.emit(lang);
  }

  protected isScrollOnTop(): boolean {
    return window.scrollY == 0;
  }

  protected toggleRespContainer() : void{
    this.isResponsiveOpen = !this.isResponsiveOpen;
  }

  protected resetScroll(): void {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }

  protected theGreatReturn(): void {
    this.router.navigate([""]);
  }

}
