<div class="block block-cover flex-col" [style.background-image]="'linear-gradient(180deg, transparent, #141414), url(' + package.background + ')'">
    <div class="black-veil"></div>
    <div class="shared-block flex-center flex-col">
        <div class="cage">
            <div class="title ct-start-4-6 ctr-start-1-6 flex-col">
                <h1 class="display_xl" data-aos="fade-up"> {{ package.title | uppercase }}</h1>
                <h2 class="body_01_xl_light" data-aos="fade-up"> {{ package.subtitle }}</h2>
            </div>
        </div>
        <div class="show-more cursor flex-col flex-center" (click)="scrollTo('SL00')">
            <img [src]="'./assets/images/Icons/'+package.icon">
            <span class="primary_button">{{ package.showmore | uppercase }}</span>
        </div>
    </div>
    <div id="SL00" class="shared-block dirt grid flex">
        <div class="cage">
            <div data-aos="fade-up" class="ct-start-2-7 ctr-start-1-6">
                <h1 class="headline_01_xl_semibold">{{ package.title001 | uppercase }}</h1>
                <p class="body_02_xl_regular">
                    {{ package.text001 }}
                </p>
            </div>

            <div class="ct-start-2-3 ctr-start-2-4 flex-center-v " data-aos="fade-up">
                <div class="cage-5 difflogo cursor" (click)="emitLink(package.link)">
                    <img class="ct-start-2-3 ctr-start-1-5" [src]="package.mediafiller">              
                </div>
            </div>
            <div data-aos="fade-up" class="ct-start-5-7 ctr-start-1-6 lined-up">

                <div>
                    <div style="display: inline-flex" class="weare">
                        <h1 class="headline_02_xl"><span class="headline_02_xl_light">{{ package.title002 | uppercase }} </span> {{ package.title003 | uppercase }}</h1>
                        <img [src]="package.mediafiller2">
                    </div>
                    <p class="body_03_xl_regular">
                        {{ package.text002 }}
                    </p>
                    <p class="body_03_xl_regular" [innerHTML]="package.text003">
                        
                    </p>
                </div>
                <div class="flex-center bracket-d">
                    <img [src]="package.mediafiller2">
                </div>
            </div>
        </div>
    </div>
</div>