import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sl17',
  templateUrl: './sl17.component.html',
  styleUrls: ['./sl17.component.scss']
})
export class SL17Component {
  @Input() package: any;
}
