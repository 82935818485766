import { AfterViewInit, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { SlideNavElement } from 'src/assets/models/SlideNavElement.model';

@Component({
  selector: 'slide-navbar',
  templateUrl: './slide-navbar.component.html',
  styleUrls: ['./slide-navbar.component.scss']
})
export class SlideNavbarComponent implements AfterViewInit {
  @Input({ required: true }) list: any;
  @Output() category = new EventEmitter<number>(); 
  protected indexOfSelectedItem: number = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.scrollTo();
  }

  ngAfterViewInit(): void {
    this.scrollTo();
  }

  protected selectItem(index: number, item?: SlideNavElement) {
    if (index >= 0 && index < this.list.slider.length) {
        this.indexOfSelectedItem = index;
        if (!!item) {
          this.category.emit(item.category);
        } else {
          this.category.emit(this.list.slider[index].category);
        }
    }

    this.scrollTo(index);
  }

  private scrollTo(index?: number): void {
    if (index != undefined) {
      let wrapperElement: HTMLElement = document.getElementById('slide-nav-wrapper');
      let selectedElement: HTMLElement = document.getElementsByClassName('sliderItem')[index] as HTMLElement;
      wrapperElement.scrollTo({left: selectedElement.offsetLeft - (wrapperElement.offsetWidth / 2) + (selectedElement.offsetWidth / 2)})
    } else {
      let wrapperElement: HTMLElement = document.getElementById('slide-nav-wrapper');
      let selectedElement: HTMLElement = document.getElementsByClassName('selected')[0] as HTMLElement;
      wrapperElement.scrollTo({left: selectedElement.offsetLeft - (wrapperElement.offsetWidth / 2) + (selectedElement.offsetWidth / 2)})
    }
  }
}
