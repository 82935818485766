import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'sl10',
  templateUrl: './sl10.component.html',
  styleUrls: ['./sl10.component.scss']
})
export class SL10Component {
  @Input() package: any;
}
