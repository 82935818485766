<ng-container *ngIf="!!packageCards && packageCards.length > 0">
    <div id="SL13" class="block black">
        <div class="cage ">
            <div data-aos="fade-up" class="ct-start-2-10 ctr-start-1-6 headline_01_xl_light flex-center text-center">
                <span>{{ package.title001 | uppercase }}</span>
            </div>
            <div data-aos="fade-up" class="ct-start-2-10 ctr-start-1-6 headline_01_s_semibold_bright flex-center text-center title">
                <span>{{ package.title002 }}</span>
            </div>
            <div class="ct-start-2-10 ctr-start-1-6 cage card-container" data-aos="fade-up">
                <ng-container *ngFor="let item of showingCards, let i = index">
                    <ng-container *ngIf="item.category == package.category">
                        <div [ngClass]="
                            {'ct-start-4-6 ctr-start-1-6': showingCards.length == 1,
                             'card-slot' : showingCards.length > 1}">
                            <card [cardObj]="item" (link)="navigateTo($event)"></card>
                        </div>
                        <div class="card-slot slot-left flex cursor resp" (click)="slideCards(-1)" *ngIf="i == 0 && !hideSliderButtons">
                            <img [src]="'./assets/images/Icons/'+package.iconleft">
                            <span class="label_small"> {{ package.line001 | uppercase }}</span>
                        </div>
                        <div class="card-slot slot-right flex cursor resp" (click)="slideCards(1)" *ngIf="i == 1 && !hideSliderButtons">
                            <span class="label_small">{{ package.line002 | uppercase }}</span>
                            <img [src]="'./assets/images/Icons/'+package.iconright">
                        </div>
                    </ng-container>
                </ng-container>
                <div class="card-slot slot-left flex cursor desk" (click)="slideCards(-1)" *ngIf="!hideSliderButtons">
                    <img [src]="'./assets/images/Icons/'+package.iconleft">
                    <span class="label_small"> {{ package.line001 | uppercase }}</span>
                </div>
                <div class="card-slot slot-right flex cursor desk" (click)="slideCards(1)" *ngIf="!hideSliderButtons">
                    <span class="label_small">{{ package.line002 | uppercase }}</span>
                    <img [src]="'./assets/images/Icons/'+package.iconright">
                </div>
            </div>
        </div>
    </div>
</ng-container>