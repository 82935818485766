import { Component, Input, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-topfooter',
    templateUrl: './app.topfooter.component.html',
    styleUrls: ['./app.topfooter.component.scss']
})

export class AppTopFooterComponent implements OnInit {

    protected company: string = environment.company;
    @Input() minimal? : boolean = false;
    @Input({required: true}) translation: any;

    constructor(private router: Router) {
      }

    ngOnInit(): void {
        
    }

    protected navigateTo(link: string): void {
        this.router.navigate([link]);
    }
      
   
}