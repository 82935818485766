import { Component, Input } from '@angular/core';

@Component({
  selector: 'sl02',
  templateUrl: './sl02.component.html',
  styleUrls: ['./sl02.component.scss']
})
export class SL02Component {
  @Input({required: true}) package: any;

}
