import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sl01',
  templateUrl: './sl01.component.html',
  styleUrls: ['./sl01.component.scss']
})
export class SL01Component {
  @Input({required: true}) package: any;
  @Output() destinationTarget = new EventEmitter<string>();

  constructor(
    private router: Router
  ) {

  }

  protected emitDestination(link: string): void {
    this.destinationTarget.emit(link);
  }
}
