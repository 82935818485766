<sl03 *ngIf="package.type == 'SL03'" [package]="package"></sl03>
<sl05 *ngIf="package.type == 'SL05'" [package]="package"></sl05>
<sl08 *ngIf="package.type == 'SL08'" (link)="emitSL08($event)"></sl08>
<sl09 *ngIf="package.type == 'SL09'" [package]="package" (link)="emitSL09($event)"></sl09>
<sl00 *ngIf="package.type == 'SL00'" [package]="package" (link)="emitSL00($event)"></sl00>
<sl04 *ngIf="package.type == 'SL04'" [package]="package"></sl04>
<sl06 *ngIf="package.type == 'SL06'" [package]="package" (link)="emitSL06($event)"></sl06>
<sl07 *ngIf="package.type == 'SL07'" [package]="package"></sl07>
<sl10 *ngIf="package.type == 'SL10'" [package]="package"></sl10>
<sl12 *ngIf="package.type == 'SL12'" [package]="package"></sl12>
<sl13 *ngIf="package.type == 'SL13'" [package]="package"></sl13>
<sl14 *ngIf="package.type == 'SL14'" ></sl14>
<sl01 *ngIf="package.type == 'SL01'" [package]="package" (destinationTarget)="emitSL01($event)"></sl01>
<sl02 *ngIf="package.type == 'SL02'" [package]="package"></sl02>
<sl15 *ngIf="package.type == 'SL15'" [package]="package"></sl15>
<sl16 *ngIf="package.type == 'SL16'" [package]="package"></sl16>
<sl17 *ngIf="package.type == 'SL17'" [package]="package"></sl17>
<sl18 *ngIf="package.type == 'SL18'" [package]="package" (link)="emitSL18($event)"></sl18>
<sl19 *ngIf="package.type == 'SL19'" [package]="package" (link)="emitSL19($event)"></sl19>