
<div class="container flex">
    <div (click)="this.selectItem(indexOfSelectedItem-1)" class="flex-center cursor">
        <img [src]="'./assets/images/Icons/'+list.leftarrow">
    </div>
    <div id="slide-nav-wrapper" class="wrapper-container">
        <div class="wrapper flex-center-v menu_label_xl">
            <ng-container *ngFor="let item of list.slider; let i = index">
                <div class="cursor sliderItem" [class.selected]="i === indexOfSelectedItem" (click)="selectItem(i, item)">
                    <b>{{item.label}}</b>
                </div>
            </ng-container>
        </div>
    </div>

    <div (click)="this.selectItem(indexOfSelectedItem+1)" class="flex-center">
        <img  class="cursor" [src]="'./assets/images/Icons/'+list.rightarrow">
    </div>
</div>


