import { Component } from '@angular/core';

@Component({
  selector: 'sl14',
  templateUrl: './sl14.component.html',
  styleUrls: ['./sl14.component.scss']
})
export class SL14Component {

}
