import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import * as Aos from 'aos';
import { Sl04Element } from 'src/assets/models/Sl04Element.model';

@Component({
  selector: 'sl04',
  templateUrl: './sl04.component.html',
  styleUrls: ['./sl04.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('inactive', style({ height: '0', overflow: 'hidden' })),
      state('active', style({ height: '*', overflow: 'visible' })),
    ])
  ]
})
export class SL04Component implements OnInit {
  
  @Input() package: any;
  protected slide002Items: Sl04Element[];

  ngOnInit(): void {
    Aos.init({
      once: true,
      delay: 0
    })
    this.getSl04Items();
  }

  private getSl04Items(): void {
    this.slide002Items = this.package.elements;
    this.slide002Items[0].active = true;
  }

  protected toggleSl04Element(item: Sl04Element, index: number): void {
    this.slide002Items = this.slide002Items.map((element, indexToAnalyze) => {
      if (indexToAnalyze === index) {
        element.active = true;
      } else {
        element.active = false;
      }
      return element;
    });
  }

}
