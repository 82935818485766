<div id="SL06" class="block lilac_deep">
    <div class="left-half flex-center-v">
        <div class="cage responsive-only">
            <div data-aos="fade-up" class="ct-start-2-4 ctr-start-1-6 headline_01_xl_light flex-center-v" style="gap: 12px">
                {{ package.title001 | uppercase }}
            </div>
            <div data-aos="fade-up" class="ct-start-2-4 ctr-start-1-6 body_01_s_light">
                {{ package.subtitle001 | uppercase }}
            </div>
            <div data-aos="fade-up" class="ct-start-2-4 ctr-start-1-6 body_03_xl_regular" [innerHTML]="package.line001">
                
            </div>
            <div data-aos="fade-up" class="ct-start-2-4 ctr-start-1-6 menu_label_xl cursor" (click)="emitLink(package.buttonLink)">
                {{ package.line002 | uppercase }}
                <img style="gap: 12px" src="./assets/images/Icons/icon_arrowright_full.svg">
            </div>
        </div>
    </div>
    <div class="right-half" [style.background-image]="'url(' + package.background + ')'"></div>
    <div class="grid-overlay desktop-only">

        <div class="cage">
            <div data-aos="fade-up" class="ct-start-2-4 ctr-start-1-6 headline_01_xl_light flex-center-v" style="gap: 12px">
                {{ package.title001 | uppercase }}
            </div>
            <div data-aos="fade-up" class="ct-start-2-4 ctr-start-1-6 body_01_s_light">
                {{ package.subtitle001 | uppercase }}
            </div>
            <div data-aos="fade-up" class="ct-start-2-4 ctr-start-1-6 body_03_xl_regular" [innerHTML]="package.line001">
                
            </div>
            <div data-aos="fade-up" class="ct-start-2-4 ctr-start-1-6 menu_label_xl flex-center-v" >
                <span class="flex-center-v cursor" (click)="emitLink(package.buttonLink)">
                    {{ package.line002 | uppercase }}
                    <img src="./assets/images/Icons/icon_arrowright_full.svg">
                </span>
            </div>
        </div>
    </div>
</div>