import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'popup-mng',
  templateUrl: './popup-mng.component.html',
  styleUrls: ['./popup-mng.component.scss']
})
export class PopupMngComponent {
  @Input() package: any;
  @Output() trigger = new EventEmitter<void>();

  protected emitTrigger(): void {
    this.trigger.emit();
  }
}
