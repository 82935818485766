<div class="block">
    <div class="cage">
        <ng-container *ngIf="!minimal">
            <div class="ct-start-6-2 ctr-start-2-4 flex-center flex-col">
                <img class="logo" data-aos="fade-up" [src]="translation.logo">
                <img class="logo sublogo" data-aos="fade-up" [src]="translation.payoff">
            </div>

            <div class="ct-start-4-6 ctr-start-2-4 caption_title_xl flex-center cities" data-aos="fade-up">
                <ng-container *ngFor="let item of translation.elements; let i = index">
                    <span>{{ item | uppercase }}</span>
                </ng-container>
            </div>
        </ng-container>
        <div class="ct-start-1-5 ctr-start-1-6 flex-col flex-center-h service_text_01 l-cont">
            <span data-aos="fade-up"><b>{{ translation?.lines.line000 }}</b></span>
            <span data-aos="fade-up">{{ translation?.lines.line001 }}</span>
            <span data-aos="fade-up">{{ translation?.lines.line002 }}</span>
        </div>

        <div class="ct-start-8-1 ctr-start-2-1 flex-center service_text_01">
            <span data-aos="fade-up" class="link" (click)="navigateTo('/'+translation?.lines.link000)">{{ translation?.lines.line003}}</span>
        </div>

        <div class="ct-start-9-2 ctr-start-4-3 flex-center service_text_01">
            <span data-aos="fade-up" class="link" (click)="navigateTo('/' + translation?.lines.link001)">{{translation?.lines.line004 }}</span>

        </div>
        <div class="ct-start-11-2 ctr-start-1-6 flex-center r-cont cursor">
            <a href='https://www.differentfactory.it/' class="flex-center-h" target="_blank">
                <img src="./assets/images/logo_differentfactory.svg" data-aos="fade-up">
                <div class="black-box"></div>
            </a>
        </div>
    </div>