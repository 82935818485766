import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sl18',
  templateUrl: './sl18.component.html',
  styleUrls: ['./sl18.component.scss']
})
export class Sl18Component {
  @Input() package: any;
  @Output() link = new EventEmitter<string>();
  constructor(
    private router: Router
  ) {}
  
  protected emitLink(link: string):void{
    this.link.emit(link);
  }
}
