<div id="SL19" class="block black">
    <div class="cage">
        <div class="ct-start-2-10 ctr-start-1-6 flex-col" style="gap: 12px;">
            <div class="headline_01_xl_semibold"  style="margin-bottom: 10px;">{{ package.title000 }}</div>
            <div class="news_font">
                <div [innerHTML]="package.paragraph000"></div>
            </div>
            <ng-container *ngIf="!!package.images && package.images.length > 1">
                <div class="ct-start-2-10 ctr-start-1-6 cage-2 img-container">
                    <ng-container *ngFor="let item of package.images; let i = index">
                        <div class="img-slot flex-col">
                            <img [src]="item.img" class="image" />
                            <span class="menu_label_xl"> {{item.desc | uppercase }}</span>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="!!package.images && package.images.length === 1">
                <div class="ct-start-2-10 ctr-start-1-6 flex-col"  style="margin-bottom: 10px;">
                    <img [src]="package.images[0].img" class="image"/>
                    <span class="menu_label_xl"> {{package.images[0].desc | uppercase }}</span>
                </div>
            </ng-container>

            <ng-container *ngIf="package.video && package.video.length > 0 ">
                <div class="ct-start-2-10 ctr-start-1-6 flex-col" style="margin-bottom: 10px;">
                    <video [src]="package.video[0].vid" class="video" controls></video>
                    <span class="menu_label_xl"> {{package.video[0].desc | uppercase }}</span>
                </div>
            </ng-container>
            

            <div class="menu_label_xl">{{ package.date | uppercase }} </div>
            <div class="menu_label_xl">
                <span class=" flex-center-v cursor" (click)="emitLink(package.link)">
                    {{package.news | uppercase}} 
                    <img style="gap: 12px" src="./assets/images/Icons/icon_arrowright_full.svg">
                </span>  
            </div>

        </div>
    </div>