import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FooterLogo } from "src/assets/models/FooterLogo.model";

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    styleUrls: ['./app.footer.component.scss']
})

export class AppFooterComponent {

    constructor(
        
    ) {

    }

    protected images: FooterLogo[] = [
        { img: './assets/images/logo_aboutdifferent.svg', link: '' },
        { img: './assets/images/logo_anitia.svg', link: 'https://www.anitia.it' },
        { img: './assets/images/logo_dodifferent.svg', link: 'https://www.dodifferent.it' },
        { img: './assets/images/logo_happyfinish.svg', link: 'https://www.happyfinish.com' },
        { img: './assets/images/logo_metide.svg', link: 'https://www.metide.com' },
        { img: './assets/images/logo_trilogis.svg', link: 'https://www.trilogis.it' },
        { img: './assets/images/logo_waga.svg', link: 'https://www.waga.it' }
    ];

    protected navigateTo(link: string): void {
        if (!!link) {
            window.open(link, "_blank");
        }
    }
}