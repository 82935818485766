import { Component, Input } from '@angular/core';
import { Slide003Element } from 'src/assets/models/Slide003Element.model';

@Component({
  selector: 'package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent {

  @Input({required: true}) element: Slide003Element;

}
