<div id="SL01" class="block black flex-center-v" [style.background-image]="'url(' + package.background + ')'" [style.height.vh]="package.height">
    <div class="veil"></div>
    <div class="cage" style="z-index: 1;">
        <div class="ct-start-5-4 ctr-start-1-6 flex-center headline_01_xl_semibold text-center">
            {{ package.title | uppercase}}
        </div>

        <div class="ct-start-4-6 ctr-start-1-6 flex-center body_01_xl_light text-center bodytext">
           {{ package.subtitle }}
        </div>

        <div class="ct-start-6-2 ctr-start-1-6 flex-center" *ngIf="!!package.button">
            <button (click)="emitDestination(package.buttonLink)"> {{ package.button }}</button>
        </div>
    </div>
</div>