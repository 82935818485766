import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuButton } from 'src/assets/models/MenuButton.model';
import { MenuSubButton } from 'src/assets/models/MenuSubButton.model';
import { MenuTab } from 'src/assets/models/MenuTab.model';

@Component({
  selector: 'responsivemenu',
  templateUrl: './responsivemenu.component.html',
  styleUrls: ['./responsivemenu.component.scss']
})
export class ResponsivemenuComponent {

  @Input({ required: true }) menu: any[];
  @Output() link = new EventEmitter<MenuTab>();

  protected menuTabs: MenuButton[];

  constructor() { }

  ngOnInit(): void {
    this.fixDirtyData();
  }

  private fixDirtyData(): void {
    this.menu.forEach(element => {
      element.isOpen = false;
    });
  }

  protected validateChildren(item: MenuButton): void {
    if (!!item.children) {
      item.isOpen = !item.isOpen;
    } else {
      this.emitLink(item);
    }
  }

  protected emitLink(item: MenuButton | MenuSubButton): void {
    this.link.emit(({title: item.title, link: item.link} as MenuTab));
  }

  protected toggleTab(tab) {
    tab.isOpen = !tab.isOpen;
  }
}