import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Card } from 'src/assets/models/Card.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sl13',
  templateUrl: './sl13.component.html',
  styleUrls: ['./sl13.component.scss']
})
export class SL13Component {
  @Input() package: any;
  protected packageCards: Card[];
  protected showingCards: Card[];
  protected hideSliderButtons: boolean = false;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private router: Router
  ) {
    
  }

  ngOnInit(): void {
    this.getCards();
  }

  private getCards(): void {
    this.http.get('assets/'+environment.company+'/content/'+this.translate.currentLang+'/bestcases/0navigator.json', { responseType: 'text' }).subscribe((data) => {
      this.packageCards = (JSON.parse(data).items as Card[]).filter(item => item.category === this.package.category).filter(item => item.id != this.package.id);
      this.slideCards(1);
    });
  }

  slideCards(counter: number): void {
    if (this.packageCards.length > 2) {
      if (counter > 0) {
        const firstItem = this.packageCards.shift();
        this.packageCards.push(firstItem);
      }
      if (counter < 0) {
        const lastElement = this.packageCards.pop();
        this.packageCards.unshift(lastElement);
      }
      this.showingCards = [this.packageCards[0], this.packageCards[1]];
    }
    if (this.packageCards.length == 2) {
      this.showingCards = [this.packageCards[0], this.packageCards[1]];
      this.hideSliderButtons = true;
    }
    if (this.packageCards.length == 1) {
      this.showingCards = [this.packageCards[0]];
      this.hideSliderButtons = true;
    }
  }

  protected navigateTo(item: Card):void{
    this.router.navigate(['/details', item.file]);
  }
}
