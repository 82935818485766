import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sl09',
  templateUrl: './sl09.component.html',
  styleUrls: ['./sl09.component.scss']
})
export class SL09Component {
  @Input({required: true}) package: any;
  @Output() link = new EventEmitter<string>();

  emitLink(link: string): void {
    this.link.emit(link);
  }
}
