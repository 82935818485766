
<div id="SL09" class="block white">
    <div class="cage">
        <div class="ct-start-2-4 ctr-start-1-6 headline_01_xl_light">
          <span data-aos="fade-up">  {{ package.title001 | uppercase }} </span><span class="headline_01_xl_semibold" data-aos="fade-up">{{ package.title002 | uppercase }}</span>
        </div>
        <div class="ct-start-6-5 ctr-start-1-6 body_02_s_bold flex-col" data-aos="fade-up">
            <span>{{ package.line001 }}</span> <span class="body_03_xl_regular"  [innerHTML]="package.line002"></span>
            <div class="btn-cont">
                <button (click)="emitLink(package.buttonLink)">{{ package.button001 | uppercase}}</button>
            </div>
        </div>
    </div>
</div>