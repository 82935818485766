import { Component, Input } from '@angular/core';

@Component({
  selector: 'sl15',
  templateUrl: './sl15.component.html',
  styleUrls: ['./sl15.component.scss']
})
export class SL15Component {
  @Input() package: any;

}
