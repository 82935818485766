import { Component, Input } from '@angular/core';

@Component({
  selector: 'sl12',
  templateUrl: './sl12.component.html',
  styleUrls: ['./sl12.component.scss']
})
export class SL12Component {
  @Input() package: any;
}
