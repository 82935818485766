export enum ContentSelector {
    Page10 = 10, //home
    Page11 = 11, //clientdetails
    Page12 = 12, //solutions
    Page13 = 13, //privacy
    Page14 = 14, //cookies policy
    Page15 = 15, //news
    Page16 = 16, 
    Page17 = 17,
    Page18 = 18,
    Page19 = 19
}