<div id="SL02" class="block white ">
    <div class="cage">
        <div data-aos="fade-up"  class="ct-start-5-4 ctr-start-1-6 headline_01_s_light text-center">
            {{package.title000|uppercase}} <span class="headline_01_s_light"> {{package.title001|uppercase}}</span>
        </div>
        <div data-aos="fade-up"  class="ct-start-2-10 ctr-start-1-6 body_01_xl_light text-center">
            {{package.text000}}
        </div>
        <ng-container  *ngIf="!!package.img">
            <div data-aos="fade-up"  data-aos="fade-up" class="ct-start-2-10 ctr-start-2-4 border"> </div>
            <div class="ct-start-1-12 ctr-start-1-6">
                <img data-aos="fade-up" src='{{package.img}}'>
            </div> 
        </ng-container>
    </div>
</div>