<div id="SL05" class="block white">
    <div class="cage" data-aos="fade-up">
        <div class="ct-start-2-11 headline_01_xl_light"> 
            {{ package.title001 | uppercase }}<span class="headline_01_xl_semibold">{{ package.title002 | uppercase }}</span>
        </div>
        <div class="ct-start-2-11 caption_detail_xl" data-aos="fade-up">
            {{ package.title003 }}
        </div>
        <div class="ct-start-1-12">
          <carousel *ngIf="package.scrollbar.length > 0" [list]="package.scrollbar"></carousel>
        </div>
    </div>
</div>