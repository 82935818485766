<div id="SL10" class="block black flex-center" [style.background-image]="'url(' + package.background + ')'">
    <div class="black-veil"></div>
    <ng-container class="flex-center">
    <div class="cage">
        <div data-aos="fade-up" class="ct-start-6-2 ctr-start-3-2 flex-center-v">
            <img src='{{package.img}}'>
        </div>

        <div data-aos="fade-up" class="ct-start-5-4 ctr-start-1-6 label_small flex-center text-center" >
            {{ package.categoryLabel | uppercase }}
        </div>

        <div data-aos="fade-up" class="ct-start-3-8 ctr-start-1-6 headline_01_xl_semibold flex-center text-center">
            {{ package.headline | uppercase }}
        </div>

        <div data-aos="fade-up" class="ct-start-3-8 ctr-start-1-6 body_01_xl_light flex-center text-center case-topic">
            {{ package.subtitle }}
        </div>

        <div data-aos="fade-up" class="ct-start-2-10 ctr-start-1-6 border"> </div>


        <div data-aos="fade-up" class="ct-start-2-7 ctr-start-1-6 headline_02_xl">
            {{ package.content.title | uppercase }}
        </div>

        <div data-aos="fade-up" class="ct-start-2-7 ctr-start-1-6 body_02_xl_regular description-box">
            {{ package.content.description }} 
        </div>
                
        <div *ngIf="!!package.content.side && package.content.side.items.length > 0" class="ct-start-9-3 ctr-start-1-6 flex-col">
            <h1 data-aos="fade-up" class="headline_03"> {{ package.content.side.title | uppercase }}</h1>
            <h3 data-aos="fade-up" class="caption_detail_s" *ngFor="let tech of package.content.side.items">{{ tech }}</h3>
        </div>
    </div>
</ng-container>
</div>

