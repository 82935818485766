<div id="SL18" class="block white">
    <div class="cage desktop-only">
        <div data-aos="fade-up" class="ct-start-2-10 headline_01_xl_light">
            {{package.title000|uppercase}} <span class="headline_01_xl_semibold"> {{package.title001|uppercase}}</span>
        </div>
        <div *ngIf="!!package.elements" data-aos="fade-up" class="ct-start-2-10  cage-3 container-news">
            <ng-container *ngFor="let newsObj of package.elements, let i = index">
                <div class="text-container" data-aos="fade-up">
                    <img class="img-news" [src]="newsObj.img" (click)="emitLink(newsObj.file)" />
                    <div data-aos="fade-up" class="ctr-start-1-6 body_02_xl_regular" style="margin-bottom: 10px;">{{ newsObj.date}}</div>
                    <div class="headline_02_xl" style="margin-bottom: 10px;"> {{newsObj.title | uppercase}} </div>
                    <div class="body_01_s_light"> {{newsObj.subtitle}} </div>
                    <div class="body_02_xl_regular box"> {{newsObj.text000}} </div>
                    <div class="body_02_xl_regular text-link cursor flex" (click)="emitLink(newsObj.file)">
                        {{newsObj.text001}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="cage-6 responsive-only">
        <div data-aos="fade-up" class="ct-start-2-4 ctr-start-1-6 headline_01_xl_light flex-center-v" style="gap: 12px">
            {{package.title000|uppercase}} <span class="headline_01_xl_semibold"> {{package.title001|uppercase}}</span>
        </div>
        <div *ngIf="!!package.elements" data-aos="fade-up" >
            <ng-container *ngFor="let newsObj of package.elements, let i = index">
                <img class="img-news" [src]="newsObj.img" (click)="emitLink(newsObj.file)" />  
                <div data-aos="fade-up" class="ctr-start-1-6 body_02_xl_regular" style="margin-bottom: 10px;">{{ newsObj.date}}</div>
                <div data-aos="fade-up" class="ctr-start-1-6 headline_02_xl" style="margin-bottom: 10px;">{{ newsObj.title | uppercase}}</div>
                <div data-aos="fade-up" class="ctr-start-1-6 body_01_s_light"> {{newsObj.subtitle}}</div>
                <div data-aos="fade-up" class="ctr-start-1-6 body_02_xl_regular box"> {{newsObj.text000}}</div>
                <div data-aos="fade-up" class="ctr-start-1-6 body_02_xl_regular cursor text-link " (click)="emitLink(newsObj.file)">
                    {{newsObj.text001}}
                    <img style="gap: 12px" src="./assets/images/Icons/icon_arrowright_full.svg">
                </div>
            </ng-container>
        </div>
    </div>