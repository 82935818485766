<div id="SL17" class="block black">
    <div class="cage">
        <div class="ct-start-2-10 ctr-start-1-6 flex-col titles">
            <div class="headline_01_xl_semibold" >{{ package.title000 | uppercase }}</div>
            <div class="body_01_xl_light" >{{ package.subtitle000 }}</div>
            <div class="body_03_xl_regular">
                <div [innerHTML]="package.paragraph000" ></div>
            </div>
            <div class="ct-start-2-10 ctr-start-1-6 body_03_xl_regular  " >

                <ng-container *ngFor="let item of package.lines; let i = index">
                    <div class="body_02_s_bold_v2">{{ item.title }}</div>
                    <div class="body_03_xl_regular" *ngIf="!!item.line000" [innerHTML]="item.line000" ></div>

                    <ng-container *ngIf="!!item.specific">
                        <ng-container *ngFor="let item of item.specific; let i = index">
                            <div class="body_02_s_bold_v2">{{ item.title }}</div>
                            <div class="body_03_xl_regular"[innerHTML]="item.text000" ></div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!!item.table">
                        <ng-container *ngFor="let item of item.table; let i = index">
                            <div class="ct-start-2-10 ctr-start-1-6 flex flex-col tab body_03_xl_regular">
                                <div [innerHTML]="item.text000" ></div>
                                <div [innerHTML]="item.text001" ></div>
                                <div class="ct-start-2-10 ctr-start-1-6 flex table-cont  body_03_xl_regular">
                                    <table>
                                        <tr >
                                            <th [innerHTML]="item.title001"></th>
                                            <th [innerHTML]="item.title002"></th>
                                            <th [innerHTML]="item.title003"></th>
                                            <th [innerHTML]="item.title004"></th>
                                            <th [innerHTML]="item.title005"></th>
                                        </tr>
                                        <tr >
                                            <td [innerHTML]="item.line001"></td>
                                            <td [innerHTML]="item.line002"></td>
                                            <td [innerHTML]="item.line003"></td>
                                            <td [innerHTML]="item.line004"></td>
                                            <td [innerHTML]="item.line005"></td>
                                        </tr>
                                        <tr >
                                            <td *ngIf="!!item.line006" [innerHTML]="item.line006"></td>
                                            <td *ngIf="!!item.line007" [innerHTML]="item.line007"></td>
                                            <td *ngIf="!!item.line008" [innerHTML]="item.line008"></td>
                                            <td *ngIf="!!item.line009" [innerHTML]="item.line009"></td>
                                            <td *ngIf="!!item.line010" [innerHTML]="item.line010"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>