<ng-container *ngFor="let slideData of sls">
    <slide-gen [package]="slideData" 
        (SL00Data)="emitSL01($event)" 
        (SL01Data)="emitSL01($event)" 
        (SL06Data)="emitSL06($event)" 
        (SL08Data)="emitSL08($event)" 
        (SL09Data)="emitSL09($event)"    
        (SL18Data)="emitSL18($event)" 
        (SL19Data)="emitSL19($event)" 
        >
    </slide-gen>
</ng-container>