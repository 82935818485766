import { Component, Input } from '@angular/core';

@Component({
  selector: 'sl05',
  templateUrl: './sl05.component.html',
  styleUrls: ['./sl05.component.scss']
})
export class SL05Component {
  @Input({required: true}) package: any;

}
