<form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div id="SL08" class="block black">
        <div class="cage">
            <div class="ct-start-2-4 ctr-start-1-6 flex-col">
                <span data-aos="fade-up" class="headline_01_xl_light">{{ translationForm?.title000 | uppercase }}</span> 
                <span data-aos="fade-up" class="headline_01_xl_semibold"> <b>{{ translationForm?.title001 | uppercase }}</b></span>
                <span data-aos="fade-up" class="headline_02_xl_light">{{ translationForm?.subtitle000 }}</span>
                <span data-aos="fade-up" class="headline_02_xl">{{ translationForm?.subtitle001 }}</span>
            </div>
            <div class="ct-start-6-6 ctr-start-1-6 cage-6">
                <div class="ct-start-1-3 ctr-start-1-6 cage-1 anchor">
                    <input data-aos="fade-up" class="ct-start-1-1" type="text" formControlName="firstName" [placeholder]="translationForm?.line000" required>
                    <div class="pupil service_text_01" *ngIf="errorManager.firstName"> {{ translationForm?.mandatory }}</div>
                </div>
                <div class="ct-start-4-3 ctr-start-1-6 cage-1 anchor">
                    <input data-aos="fade-up" class="ct-start-1-1" type="text" formControlName="lastName" [placeholder]="translationForm?.line001" required>
                    <div class="pupil service_text_01" *ngIf="errorManager.lastName"> {{ translationForm?.mandatory }}</div>
                </div>
                <div class="ct-start-1-3 ctr-start-1-6 cage-1 anchor">
                    <input data-aos="fade-up" class="ct-start-1-1" type="text" formControlName="phone" [placeholder]="translationForm?.line002" required>
                    <div class="pupil service_text_01" *ngIf="errorManager.phone"> {{ translationForm?.mandatory }}</div>
                </div>
                <div class="ct-start-4-3 ctr-start-1-6 cage-1 anchor">
                    <input data-aos="fade-up" class="ct-start-1-1" type="text" formControlName="company" [placeholder]="translationForm?.line003" required>
                    <div class="pupil service_text_01" *ngIf="errorManager.company"> {{ translationForm?.mandatory }}</div>
                </div>
                <div class="ct-start-1-6 cage-1 anchor">
                    <input data-aos="fade-up" class="ct-start-1-1" type="text" formControlName="email" [placeholder]="translationForm?.line004" required>
                    <div class="pupil service_text_01" *ngIf="errorManager.email"> {{ translationForm?.mandatory }}</div>
                </div>
                <div class="ct-start-1-6 cage-1 anchor">
                    <textarea data-aos="fade-up" class="ct-start-1-1" formControlName="message" [placeholder]="translationForm?.line005" required></textarea>
                    <div class="pupil service_text_01" *ngIf="errorManager.message"> {{ translationForm?.mandatory }}</div>
                </div>
            </div>
            <div  class="ct-start-2-10 ctr-start-1-6 flex-center-v">
                <div class="cage-10">
                    <div data-aos="fade-up" class="ct-start-1-4 desktop-only headline_03">
                        <b>{{translationForm?.text000 | uppercase }}</b>
                    </div> 
        
                    <div class="ct-start-5-3 ctr-start-1-6 body_02_s_bold anchor">
                        <input type="checkbox" formControlName="policy" required>
                        <label for="policy">{{ translationForm?.line006 }} </label>                  
                        <div class="pupil-cb service_text_01" *ngIf="errorManager.policy"> {{ translationForm?.mandatory }}</div>
                    </div>
                </div>
            </div>
            <div class="ct-start-2-10 ctr-start-1-6 flex-center-v">
                <div class="cage-10">
                    <div class="ct-start-1-4 flex-center-v desktop-only cursor" style="gap:24px" (click)="emitLink(translationForm?.link)">
                        <img class="logo" data-aos="fade-up" [src]="translationForm?.linkedin">
                        <span data-aos="fade-up" class="caption_title_xl">{{ translationForm?.text001 }}</span>
                    </div>
                    <div class="ct-start-5-6 ctr-start-1-6">
                        <button data-aos="fade-up" type="submit"> {{ translationForm?.line007 }}</button>   
                    </div>
                </div>
            </div>
            <div class="ctr-start-1-6 responsive-only flex-center-v">
                <div class="cage-6">
                    <div data-aos="fade-up" class="ctr-start-1-3 headline_03 flex-center-v">
                        <b>{{ translationForm?.text000 | uppercase }}</b>
                    </div>
                    
                    <div class="ctr-start-4-3 flex-center-v cursor" style="gap:24px" (click)="emitLink(translationForm?.link)">
                            <img class="logo" data-aos="fade-up" [src]="translationForm?.linkedin">
                            <span data-aos="fade-up" class="caption_title_xl">{{ translationForm?.text001 }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<popup-mng *ngIf="showPopup" [package]="translationForm.popup" (trigger)="this.closePopupAndReset(); "></popup-mng>