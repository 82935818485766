import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageOwnerComponent } from './pages/page-owner/page-owner.component';

const routes: Routes = [
  { path: "", component: PageOwnerComponent},
  { path: "details/:client", component: PageOwnerComponent},
  { path: "article/:client", component: PageOwnerComponent},
  { path: "solutions", component: PageOwnerComponent},
  { path: "privacy", component: PageOwnerComponent},
  { path: "cookies", component: PageOwnerComponent},
  { path: "news", component: PageOwnerComponent},
  { path: "**", component: PageOwnerComponent, pathMatch: "full"},
  // { path: "test", component: PageOwnerComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const RoutingComponents = [
  PageOwnerComponent
]
