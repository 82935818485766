import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AppRoutingModule, RoutingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CardComponent } from './components/card/card.component';
import { ResponsivemenuComponent } from './components/navigation-menu/responsivemenu/responsivemenu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PackageComponent } from './components/package/package.component';
import { SL08Component } from './components/slides/sl08/sl08.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { AppTopFooterComponent } from './components/topfooter/app.topfooter.component';
import { AppFooterComponent } from './components/footer/app.footer.component';
import { SlideNavbarComponent } from './components/slide-navbar/slide-navbar.component';
import { PageGenComponent } from './pages/page-owner/page-gen/page-gen.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { SlideGenComponent } from './components/slide-gen/slide-gen.component';
import { SL03Component } from './components/slides/sl03/sl03.component';
import { SL05Component } from './components/slides/sl05/sl05.component';
import { SL01Component } from './components/slides/sl01/sl01.component';
import { SL00Component } from './components/slides/sl00/sl00.component';
import { SL04Component } from './components/slides/sl04/sl04.component';
import { SL06Component } from './components/slides/sl06/sl06.component';
import { SL07Component } from './components/slides/sl07/sl07.component';
import { SL02Component } from './components/slides/sl02/sl02.component';
import { SL09Component } from './components/slides/sl09/sl09.component';
import { SL10Component } from './components/slides/sl10/sl10.component';
import { SL12Component } from './components/slides/sl12/sl12.component';
import { SL13Component } from './components/slides/sl13/sl13.component';
import { SL14Component } from './components/slides/sl14/sl14.component';
import { SL15Component } from './components/slides/sl15/sl15.component';
import { SL16Component } from './components/slides/sl16/sl16.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopupMngComponent } from './components/popup-mng/popup-mng.component';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { SL17Component } from './components/slides/sl17/sl17.component';
import { Configuration, MailsControllerService } from 'src/services/api-docs-services';
import { environment } from 'src/environments/environment';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { CookiebotConfig } from './config/cookiebot.config';
import { Sl18Component } from './components/slides/sl18/sl18.component';
import { Sl19Component } from './components/slides/sl19/sl19.component';
@NgModule({
  declarations: [
    AppComponent,
    AppFooterComponent,
    AppTopFooterComponent,
    RoutingComponents,
    CardComponent,
    ResponsivemenuComponent,
    PackageComponent,
    SL08Component,
    SlideNavbarComponent,
    CarouselComponent,
    PageGenComponent,
    NavigationMenuComponent,
    SlideGenComponent,
    SL03Component,
    SL05Component,
    SL01Component,
    SL00Component,
    SL04Component,
    SL06Component,
    SL07Component,
    SL02Component,
    SL09Component,
    SL10Component,
    SL12Component,
    SL13Component,
    SL14Component,
    SL15Component,
    SL16Component,
    PopupMngComponent,
    SL17Component,
    Sl18Component,
    Sl19Component
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    HttpClientModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    NgxCookiebotModule.forRoot(CookiebotConfig)
  ],
  providers: [
    MailsControllerService,
    { 
      provide: ErrorStateMatcher, 
      useClass: ShowOnDirtyErrorStateMatcher
    },
    {
      provide: Configuration,
      useFactory: () => {
        return new Configuration({
            accessToken: environment.mailTokenId  
        });
      },
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}