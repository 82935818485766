import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Card } from 'src/assets/models/Card.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sl07',
  templateUrl: './sl07.component.html',
  styleUrls: ['./sl07.component.scss']
})
export class SL07Component implements OnInit {
  
  @Input() package: any;
  protected packageCards: any;
  protected slide004CategoryInView: number = null;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private http: HttpClient
  ) {
    
  }

  ngOnInit(): void {
    this.getCards();
  }

  private getCards(): void {
    this.http.get('assets/'+environment.company+'/content/'+this.translate.currentLang+'/bestcases/0navigator.json', { responseType: 'text' }).subscribe((data) => {
      this.packageCards = JSON.parse(data);
    });
  }
  
  protected navigateTo(item: Card):void{
    this.router.navigate(['/details', item.file]);
  }

  protected assignCategory(item: any): void {
    this.slide004CategoryInView = item;
  }
}
