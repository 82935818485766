import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { CarouselElement } from 'src/assets/models/CarouselElement';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
  @Input({ required: true }) list: CarouselElement[];

  slideIndex: number = 0;
  @ViewChild('backstage') backstage: ElementRef;
  @ViewChild('showlist') showlist: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resetComponent();
  }

  private resetComponent(): void {
    this.slideIndex = 0;
    (this.backstage.nativeElement as HTMLElement).style.left = '0px';
  }

  changeSlide(offset: number) {
    const originalLeft: number = (this.backstage.nativeElement as HTMLElement).offsetLeft;
    const addLeftAmount: number = offset * ((this.showlist.nativeElement as HTMLElement).offsetWidth / 3);
    (this.backstage.nativeElement as HTMLElement).style.left = originalLeft + addLeftAmount + 'px';

    offset > 0 ? this.slideIndex-- : this.slideIndex++;
  }

  protected scrollLeft(): void {
    if (this.slideIndex == 0) {
      return;
    }

    const originalLeft: number = (this.backstage.nativeElement as HTMLElement).offsetLeft;
    const addLeftAmount: number = ((this.showlist.nativeElement as HTMLElement).offsetWidth / 3);
    (this.backstage.nativeElement as HTMLElement).style.left = originalLeft + addLeftAmount + 'px';

    this.slideIndex--;
  }

  protected scrollRight(): void {
    if (!this.determineEndingPoint()) {
      return;
    }

    const originalLeft: number = (this.backstage.nativeElement as HTMLElement).offsetLeft;
    const addLeftAmount: number = ((this.showlist.nativeElement as HTMLElement).offsetWidth / 3);
    (this.backstage.nativeElement as HTMLElement).style.left = originalLeft - addLeftAmount + 'px';

    this.slideIndex++;
  }

  protected determineEndingPoint(): boolean {
    let isVisible = true;

    if (this.list.length < 3 || this.slideIndex == this.list.length-3) {
      isVisible = false;
    }

    return isVisible;
  }


}
