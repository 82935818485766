
import { NgxCookiebotConfig } from '@halloverden/ngx-cookiebot';
import { environment } from 'src/environments/environment';

export class CookiebotConfig extends NgxCookiebotConfig {
  override widgetDistanceVertical?: number;
  override widgetDistanceHorizontal?: number;

  constructor() {
    super();
    this.blockingMode = 'manual';
    this.cdn = 'com';
    this.loadScript = true;
    this.widgetEnabled = true;
    this.widgetPosition = 'bottom-left';
    this.cbId = environment.cookieBotDomainGroupId;
    // this.culture = 'IT';
    // this.type = '';
  }
}